<template>
  <el-main>
    <div class="top">
      <div class="left">
        <div class="bigName">群发详情</div>
        <div class="infoItem">
          <div class="title">任务名称：</div>
          <div class="content">{{ msgInfo.name }}</div>
        </div>
        <div class="infoItem">
          <div class="title">群发类型：</div>
          <div class="content">{{ msgInfo.send_type == 1 ? '立即发送' : '定时发送' }}</div>
        </div>
        <div class="infoItem" v-if="msgInfo.send_type == 2">
          <div class="title">发送时间：</div>
          <div class="content">{{ getDateformat(msgInfo.send_time) }}</div>
        </div>
        <div class="bigName">群发统计</div>
        <el-table :data="msgInfo.list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
          <template slot="empty">
            <No />
          </template>
          <el-table-column prop="name" label="成员名称" align="center"> </el-table-column>
          <el-table-column label="发送状态" align="center">
            <template v-slot="{ row }">
              {{ row.status === 0 ? '未发送' : '已发送' }}
            </template>
          </el-table-column>
          <el-table-column label="发送时间" align="center">
            <template v-slot="{ row }">
              {{ row.status === 0 ? '--' : getDateformat(row.send_time) }}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template v-slot="{ row }">
              <el-button type="text" @click="lookDetail(row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="right">
        <PhoneShow :data="msgInfo.attachments" :noOperation="true"></PhoneShow>
      </div>
      <el-dialog title="群发详情" :visible.sync="lookDetailFlag" width="600px">
        <el-table :data="detailInfo" :header-cell-style="{ 'background-color': '#F8F9FA' }">
          <template slot="empty">
            <No />
          </template>
          <el-table-column label="群信息" align="center">
            <template v-slot="{ row }">
              <div class="infoBox">
                <div class="infoLeft">
                  <img src="@/assets/personalImg.png" alt="" />
                </div>
                <div class="infoRight">
                  <div>
                    {{
                      $route.path === '/user/messageTeam/messageDetail' ? '群名称：' : '客户名称：'
                    }}{{ row.name }}
                  </div>
                  <div>群主：{{ row.userid }}</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="群发状态" align="center">
            <template v-slot="{ row }">
              <div class="sendStatus">
                {{
                  row.status === 0
                    ? '未发送'
                    : row.status === 1
                    ? '已发送'
                    : row.status === 2
                    ? '客户不是好友导致发送失败'
                    : row.status === 3
                    ? '客户已经收到其他群发消息导致发送失败'
                    : ''
                }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
    </div>
  </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate'
import PhoneShow from './components/phoneShow'
export default {
  components: {
    PhoneShow
  },
  created() {
    this.msgInfo = this.$route.query
    if (this.msgInfo.is_attachments == 1) {
      let str = this.msgInfo.attachments
      this.msgInfo.attachments = JSON.parse(str)
    }
    this.getUserSend()
  },
  data() {
    return {
      msgInfo: null,
      getDateformat,
      lookDetailFlag: false,
      detailInfo: []
    }
  },
  methods: {
    getUserSend() {
      this.$axios
        .post(this.$api.user.getUserSend, {
          id: this.msgInfo.id
        })
        .then((res) => {
          if (res.code === 0) {
            this.$set(this.msgInfo, 'list', res.result)
          }
        })
    },
    lookDetail(row) {
      this.$axios
        .post(this.$api.user.getUserSendInfo, {
          msgid: row.msgid,
          userid: row.userid
        })
        .then((res) => {
          if (res.code === 0) {
            this.detailInfo = res.result
            this.lookDetailFlag = true
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  .top {
    display: flex;
    & > div {
      flex: 1;
    }
    .left {
      .infoItem {
        display: flex;
        align-items: center;
        line-height: 40px;
        font-size: 14px;
        .title {
          width: 100px;
          text-align: right;
          white-space: nowrap;
          flex-shrink: 0;
        }
      }
      .bigName {
        font-size: 18px;
        font-weight: bold;
        margin: 20px 0;
      }
    }
    .right {
      display: flex;
      justify-content: center;
    }
  }
  .infoBox {
    display: flex;
    .infoRight {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-left: 10px;
      text-align: left;
    }
  }
}
</style>
