var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-main',[_c('div',{staticClass:"top"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"bigName"},[_vm._v("群发详情")]),_c('div',{staticClass:"infoItem"},[_c('div',{staticClass:"title"},[_vm._v("任务名称：")]),_c('div',{staticClass:"content"},[_vm._v(_vm._s(_vm.msgInfo.name))])]),_c('div',{staticClass:"infoItem"},[_c('div',{staticClass:"title"},[_vm._v("群发类型：")]),_c('div',{staticClass:"content"},[_vm._v(_vm._s(_vm.msgInfo.send_type == 1 ? '立即发送' : '定时发送'))])]),(_vm.msgInfo.send_type == 2)?_c('div',{staticClass:"infoItem"},[_c('div',{staticClass:"title"},[_vm._v("发送时间：")]),_c('div',{staticClass:"content"},[_vm._v(_vm._s(_vm.getDateformat(_vm.msgInfo.send_time)))])]):_vm._e(),_c('div',{staticClass:"bigName"},[_vm._v("群发统计")]),_c('el-table',{attrs:{"data":_vm.msgInfo.list,"header-cell-style":{ 'background-color': '#F8F9FA' }}},[_c('template',{slot:"empty"},[_c('No')],1),_c('el-table-column',{attrs:{"prop":"name","label":"成员名称","align":"center"}}),_c('el-table-column',{attrs:{"label":"发送状态","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.status === 0 ? '未发送' : '已发送')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"发送时间","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.status === 0 ? '--' : _vm.getDateformat(row.send_time))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.lookDetail(row)}}},[_vm._v("详情")])]}}])})],2)],1),_c('div',{staticClass:"right"},[_c('PhoneShow',{attrs:{"data":_vm.msgInfo.attachments,"noOperation":true}})],1),_c('el-dialog',{attrs:{"title":"群发详情","visible":_vm.lookDetailFlag,"width":"600px"},on:{"update:visible":function($event){_vm.lookDetailFlag=$event}}},[_c('el-table',{attrs:{"data":_vm.detailInfo,"header-cell-style":{ 'background-color': '#F8F9FA' }}},[_c('template',{slot:"empty"},[_c('No')],1),_c('el-table-column',{attrs:{"label":"群信息","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"infoBox"},[_c('div',{staticClass:"infoLeft"},[_c('img',{attrs:{"src":require("@/assets/personalImg.png"),"alt":""}})]),_c('div',{staticClass:"infoRight"},[_c('div',[_vm._v(" "+_vm._s(_vm.$route.path === '/user/messageTeam/messageDetail' ? '群名称：' : '客户名称：')+_vm._s(row.name)+" ")]),_c('div',[_vm._v("群主："+_vm._s(row.userid))])])])]}}])}),_c('el-table-column',{attrs:{"label":"群发状态","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"sendStatus"},[_vm._v(" "+_vm._s(row.status === 0 ? '未发送' : row.status === 1 ? '已发送' : row.status === 2 ? '客户不是好友导致发送失败' : row.status === 3 ? '客户已经收到其他群发消息导致发送失败' : '')+" ")])]}}])})],2)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }