<template>
  <div class="phoneBox">
    <div class="phoneContent">
      <vuedraggable
        v-model="data"
        :disabled="noOperation"
        v-bind="{
          animation: 200,
          group: 'description',
          disabled: false,
          ghostClass: 'ghost',
          scroll: true
        }"
      >
        <transition-group>
          <div class="chatItem" v-for="(item, index) in data" :key="index">
            <div class="left">
              <img
                :style="headImg"
                :src="imgUrlHead + 'distribution/groupPhoneHeaderImg.png'"
                alt=""
              />
            </div>
            <div class="right">
              <div class="deleteBox" v-if="!noOperation">
                <div>
                  <el-tooltip class="item" effect="dark" content="删除该附件" placement="right">
                    <img src="@/assets/deletefj.png" alt="" @click="deleteThisFj(index)" />
                  </el-tooltip>
                </div>
                <div>
                  <el-tooltip class="item" effect="dark" content="编辑该附件" placement="right">
                    <img src="@/assets/updatefj.png" alt="" @click="updateThis(item, index)" />
                  </el-tooltip>
                </div>
              </div>
              <div class="rightTop">彩蛋生活馆<span>@彩蛋数字</span></div>
              <div class="rightBot" v-if="item.msgtype === 'image'">
                <img v-if="item.image.pic_url" :src="item.image.pic_url" alt="" />
                <div class="chatBox" v-else>暂未添加图片</div>
              </div>
              <div class="rightBot rightBoxlink" v-if="item.msgtype === 'link'">
                <div class="linkBox">
                  <div class="top">{{ item.link.title }}</div>
                  <div class="bot">
                    <div class="linkDesc">{{ item.link.desc }}</div>
                    <div class="linkImg"><img :src="item.link.picurl" alt="" /></div>
                  </div>
                </div>
              </div>
              <div class="rightBot rightBoxlink" v-if="item.msgtype === 'miniprogram'">
                <div class="appBox">
                  <div class="top"><img src="@/assets/loadingLogo.png" alt="" />彩蛋数字</div>
                  <div class="mid">
                    <div class="title">{{ item.miniprogram.title }}</div>
                    <img
                      v-if="item.miniprogram.path"
                      class="img"
                      :src="item.miniprogram.path"
                      alt=""
                    />
                    <div v-else class="img"></div>
                  </div>
                  <div class="bot"><img src="@/assets/appLink.png" alt="" />小程序</div>
                </div>
              </div>
              <div class="rightBot" v-if="item.msgtype === 'video'">
                <video v-if="item.video.path" controls :src="item.video.path" alt=""></video>
                <div class="chatBox" v-else>暂未添加视频</div>
              </div>
              <div class="rightBot rightBoxlink" v-if="item.msgtype === 'file'">
                <div class="fileBox">
                  <div class="left">
                    <div class="top">{{ item.file.name }}</div>
                    <div class="bottom">{{ item.file.showSize }}</div>
                  </div>
                  <div class="right">
                    <img src="@/assets/fileAllPic.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </vuedraggable>
    </div>
  </div>
</template>

<script>
import { imgUrlHead } from '@/util/config.js'
import vuedraggable from 'vuedraggable'
import { bus } from '@/util/bus'
export default {
  components: {
    vuedraggable
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    noOperation: {
      type: Boolean,
      default: () => false
    }
  },
  created() {
    let ranNum = Math.floor(Math.random() * 2)
    this.headImg.transform = `translateX(-${ranNum * 45}px)`
  },
  data() {
    return {
      imgUrlHead,
      headImg: {
        transform: 'translateX(0)'
      }
    }
  },
  methods: {
    updateThis(item, index) {
      bus.$emit('changeNowUpdate', item, index)
    },
    deleteThisFj(index) {
      bus.$emit('deleteThisFj', index)
    }
  }
}
</script>

<style lang="less" scoped>
.phoneBox {
  width: 400px;
  height: 797px;
  background-image: url('../../../../assets/image/phoneShow.png');
  position: relative;
  .phoneContent {
    width: 375px;
    height: 624px;
    position: absolute;
    top: 98px;
    left: 10px;
    border-radius: 0 0 8px 8px;
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 20px;
    &::-webkit-scrollbar {
      width: 0px;
    }
    .chatItem {
      display: flex;
      padding: 0 10px;
      padding-top: 10px;
      margin-bottom: 30px;

      .left {
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        border-radius: 4px;
        overflow: hidden;
        margin-right: 10px;

        img {
          height: 100%;
        }
      }
      .right {
        position: relative;
        padding-top: 2px;
        .deleteBox {
          width: 34px;
          position: absolute;
          top: 16px;
          right: -40px;
          background-color: #fff;
          border-radius: 4px;
          z-index: 2;
          & > div {
            width: 100%;
            height: 34px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            img {
              width: 20px;
              height: 20px;
            }
          }

          &::before {
            content: '';
            border: 6px solid #fff;
            position: absolute;
            top: 10px;
            left: -12px;
            border-color: transparent #fff transparent transparent;
          }
        }
        .rightTop {
          font-size: 12px;
          color: #888888;
          span {
            color: #e6995c;
          }
        }
        .rightBot {
          max-width: 150px;
          background-color: #fff;
          border-radius: 4px;
          overflow: hidden;
          border: 1px solid #ccc;
          font-size: 14px;
          img {
            max-width: 100%;
            object-fit: cover;
          }
          video {
            max-width: 100%;
            object-fit: cover;
          }
          .chatBox {
            padding: 5px;
          }
          .linkBox {
            width: 250px;
            height: 80px;
            background-color: #fff;
            padding: 10px;
            .top {
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 14px;
              font-weight: bold;
            }
            .bot {
              display: flex;
              .linkDesc {
                flex: 1;
                white-space: pre-wrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                padding-top: 7px;
              }
              .linkImg {
                width: 40px;
                height: 40px;
                flex-shrink: 0;
                margin-left: 10px;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
          .appBox {
            width: 250px;
            height: 300px;
            padding: 10px 10px 0 10px;
            font-size: 12px;
            display: flex;
            flex-direction: column;
            .top {
              line-height: 25px;
              img {
                width: 25px;
                height: 25px;
                border-radius: 50%;
                margin-right: 5px;
              }
            }
            .mid {
              margin: 10px 0 5px 0;
              flex: 1;
              .title {
                font-size: 16px;
                font-weight: bold;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              .img {
                margin: 10px 0 5px 0;
                width: 100%;
                height: 197px;
              }
            }
            .bot {
              display: flex;
              align-items: center;
              border-top: 1px solid #e4e4e4;
              height: 20px;
              line-height: 20px;
              color: #a0a2a1;
              img {
                transform: scale(0.8);
              }
            }
          }
          .fileBox {
            width: 250px;
            height: 80px;
            background-color: #fff;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left {
              flex: 1;
              height: 100%;
              .top {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                margin-bottom: 5px;
              }
            }
          }
        }
        .rightBoxlink {
          max-width: 250px !important;
        }
      }
    }
  }
}
</style>
